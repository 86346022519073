@font-face {
  font-family: 'Oswald-Bold';
  src: url('/public/assets/fonts/Oswald-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Oswald-ExtraLight';
  src: url('/public/assets/fonts/Oswald-ExtraLight.otf') format('opentype');
}

@font-face {
  font-family: 'Oswald-Light';
  src: url('/public/assets/fonts/Oswald-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Oswald-Medium';
  src: url('/public/assets/fonts/Oswald-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Oswald-Regular';
  src: url('/public/assets/fonts/Oswald-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Oswald-SemiBold';
  src: url('/public/assets/fonts/Oswald-SemiBold.otf') format('opentype');
}
body {
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  font-family: Open Sans, sans-serif;
  background-color: #222;
}
body {
  height: 100%;
  background: url(/public/assets/images/background-film-white.png) no-repeat;
  background-color: #141414;
  color: #fff;
}

h1,
h2,
h3 {
  font-family: Oswald, sans-serif;
}
h1 {
  font-size: 2.441em;
  font-weight: 600;
  color: #fff;
  font-family: Oswald, sans-serif;
  letter-spacing: -0.45px;
}
.home-heading {
  color: #e2bb46;
  font-weight: 700;
}
.page-section {
  padding: 40px 20px 0px 40px;
}
.btn-primary {
  text-align: center;
  border: 2px solid whitesmoke;
  color: #1f1c4d;
  font-weight: 900;
  background: #e2bb46;
  padding: 5px 40px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 1.5em;
  transition: all 0.4s ease-in-out;
  margin-bottom: 20px;
  border-radius: 20px;
  font-family: Oswald, sans-serif;
  cursor: pointer;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active {
  background: #141414;
  border-color: #e2bb46;
  color: #fff;
  box-shadow: 0 0 18px #c4a35d99;
}
.underline {
  text-decoration: underline;
}
.perks-content-un {
  z-index: 1;
  margin-top: 9%;
  height: 10%;
  background: #e2bb46;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px 30px 20px 70px;
  border-radius: 20px;
}
.perks-content-un h1 {
  color: #1f1c4d;
  margin: 10px 0 15px;
  line-height: 1.4;
  padding-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 2em;
}
.perks-content-un .font-weight-bold {
  font-weight: 700 !important;
  line-height: 1;
  color: #141414;
}
.accordion .card {
  background-color: #e2bb46;
  border-bottom: 1px solid #141414;
}
.accordion .bg-white {
  background-color: #fff !important;
}
.accordion .bg-white a {
  color: #000;
  text-decoration: underline;
}
.accordion .card-body {
  color: #141414;
  font-family: Open Sans, sans-serif;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 10px;
  font-size: 1em;
  padding: 2.25rem 1.25rem 1.25rem 2.25rem;
}
.accordion .card .header-title {
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  color: #141414;
  font-weight: 600;
  letter-spacing: -0.45px;
  line-height: 1.15;
  font-size: 1em;
  border-bottom: 0 !important;
}
.accordion .card-header {
  border-bottom: 0 !important;
}
.img-perks {
  z-index: 2;
  visibility: visible !important;
}
.img-perks img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}
.btn-primary:disabled {
  background-color: #f0f0e6;
  color: #6b6b6b;
  border-color: #6b6b6b;
}

.btn-primary:hover:disabled {
  background-color: #f0f0e6;
  color: #6b6b6b;
  border-color: #6b6b6b;
}
.btn-default {
  border: none;
  border-radius: 25px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  color: #e2bb46;
  background-color: #141414;
  font-family: Open Sans, sans-serif;
  font-weight: 800;
  padding: 5px 30px;
}
.btn-default:hover {
  color: #f0f0e6;
}
.btn-secondary {
  background-color: #333333;
  color: #f0f0e6;
  border: 1px solid #00bd3f;
  border-radius: 30px;
  padding: 10px 30px;
}
.btn-secondary:hover {
  background-color: #6b6b6b;
  color: #f0f0e6;
  border: 1px solid #00bd3f;
}

.btn-light {
  background-color: #f0f0e6;
  color: #333333;
  border: 1px solid #f0f0e6;
  border-radius: 30px;
  padding: 10px 30px;
  font-family: 'HelveticaNeue-Medium';
  font-weight: normal;
}

.text-link {
  font-size: 20px;
  text-transform: uppercase;
  color: #333333;
}

.text-link:hover {
  color: #000;
  text-decoration: none;
}

.span-text-link {
  font-size: 20px;
  color: #333333;
  padding: 0;
  vertical-align: baseline;
  border: none;
  text-decoration: underline;
  box-shadow: none;
}

.span-text-link:hover {
  color: #000;
  text-decoration: underline;
  box-shadow: none;
}

.span-text-link:focus {
  box-shadow: none;
}

.green-text-link {
  font-size: 20px;
  color: #006228;
  padding: 0;
  vertical-align: baseline;
  border: none;
  border-bottom: 2px solid #006228;
  text-decoration: none;
  box-shadow: none;
}

.green-text-link:hover {
  color: #006228;
  text-decoration: none;
  box-shadow: none;
}

.green-text-link:focus {
  box-shadow: none;
  text-decoration: none;
}

.text-link-plain {
  font-size: 20px;
  color: #333333;
  padding: 0;
  vertical-align: baseline;
  border: none;
  text-decoration: none;
  box-shadow: none;
}

.text-link-plain:hover {
  color: #000;
  text-decoration: none;
  box-shadow: none;
}

.text-link-plain:focus {
  box-shadow: none;
}

.form-group .form-control:disabled {
  background-color: #f0f0e6;
  border-color: #6b6b6b;
}

.navbar-dark {
  color: #f0f0e6;
  background: #141414;
  border-bottom: 20px solid #e2bb46;
  top: 0;
  box-shadow: 0 3px 15px #0000004d;
  height: 80px;
}

.navbar-dark .navbar-brand {
  margin-right: 40px;
}

.navbar-dark .navbar-brand img {
  width: 100%;
  margin-bottom: -20px;
}
.navbar-dark .navbar-brand span {
  margin-top: 12px;
  font-family: Open Sans, sans-serif !important;
  position: absolute;
  text-transform: uppercase;
  font-size: 1.6em;
  font-weight: 600 !important;
  color: #fff;
  top: 0;
  -ms-transform: translate(0px, 16px);
  transform: translateY(16px);
}

.navbar-nav .nav-item .nav-link {
  font-size: 0.9em;
  text-transform: uppercase;
  font-family: Open Sans, sans-serif;
  color: #fff;
  padding: 12px 10px 0px 5px;
  font-weight: 600;
}
.navbar-nav .nav-item .nav-link:hover {
  color: #e2bb46;
}
.navbar-nav .menu-btn {
  font-size: 0.8em;
  text-transform: uppercase;
  background: #e2bb46;
  padding: 5px 20px;
  border: none;
  border-radius: 25px;
  color: #141414;
  font-weight: 500;
  cursor: pointer;
  font-family: Open Sans, sans-serif;
}
.navbar-nav .menu-btn:hover {
  border: none;
  background-color: #000;
  color: #fff;
  box-shadow: 0 0 18px #c4a35d99;
}
.user-menu-icon {
  color: #f0f0e6;
  margin-right: 50px;
}

.user-menu-icon:hover {
  color: #f0f0e6;
}

.user-name-dd {
  font-size: 16px;
  font-family: Open Sans, sans-serif;
}

.user-name-dd span {
  font-family: Open Sans, sans-serif;
}
.dropdown-menu-right {
  border: 2px solid #e2bb46;
}
.dropdown-menu-right .nav-item .nav-link {
  color: #f0f0e6 !important;
  background-color: #141414 !important;
  font-family: Open Sans, sans-serif;
}

.dropdown-menu-right .nav-item:hover .nav-link {
  color: #f0f0e6 !important;
  background-color: #141414 !important;
}

.mob-menu-pos {
  float: right !important;
}

.user-menu-icon {
  border: 0;
  background: none;
}

.user-name-dd {
  width: 120px;
  margin-right: 10px;
  text-align: right !important;
}

.userprofile-icon {
  width: 50px;
  vertical-align: text-bottom;
  /* filter: invert(1); */
}

.user-menu .dropdown-toggle::after {
  margin-left: -4em;
  vertical-align: 0.6em;
}

.dd-item-icon {
  width: 32px;
  padding-right: 6px;
}

.user-menu ul.dropdown-menu {
  margin-top: 10px;
}

.user-menu .dropdown-item a {
  text-decoration: none;
  display: block;
  text-align: left;
  line-height: 34px;
  padding-left: 0;
  width: 100%;
}

ul.dropdown-menu li a:hover {
  color: #f0f0e6;
  background-color: #333333;
}

.user-menu .dropdown-menu button.btn-profile {
  padding: 0;
  outline: none;
  border: none;
}

.plaidpark_logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 116px;
}

.plaidpark_logo img {
  padding-top: 5px;
  width: 116px;
}

.form-control::placeholder,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::placeholder,
input:-moz-placeholder {
  color: #fff !important;
  opacity: 0 !important;
}

.show-placeholder.MuiInputBase-input::placeholder,
.show-placeholder.form-control::placeholder,
.show-placeholder input::placeholder {
  color: #000 !important;
  opacity: 0.6 !important;
}

.post-login h2 {
  margin-bottom: 30px;
  text-transform: uppercase;
}

.p_note {
  font-size: 22px;
}

/* home page */
.hero-banner {
}

.hero-banner-cta-wrap {
  text-align: left;
}

.hero-banner .btn-primary {
  text-transform: capitalize !important;
  padding: 10px 30px !important;
  min-width: auto;
  font-size: 16px;
  font-weight: normal;
  border-radius: 30px;
  border: 1px solid #fff;
  min-width: 160px;
  text-align: center;
}

.easy_rewards {
}

.easy_rewards h2 {
  font-size: 44px;
}

.easy_rewards ol {
  margin: 60px 0 0 30px;
  padding: 0;
}

.easy_rewards ol li {
  margin: 16px 0;
  padding: 0;
  font-family: 'HelveticaNeue-Bold';
  font-size: 30px;
  line-height: 110%;
}

.get-access-section {
  background-color: #f0f0e6;
  padding-bottom: 80px;
}

/* .react-multi-carousel-list{overflow: initial !important;}
.react-multi-carousel-track{overflow: hidden; width: 100%;} 
.react-multi-carousel-dot-list{bottom: -20px;}*/
.react-multi-carousel-dot button {
  width: 16px;
  height: 16px;
}

.get-access-section .heading-col {
  padding: 40px 0;
}

.get-access-section p {
  font-size: 26px;
  text-align: center;
  line-height: 30px;
}

.feed-it_section {
}

.feed-it_featured {
  position: relative;
  height: 120px;
}

.feed-it_featured::after {
  position: absolute;
  content: '';
  background: url(/public/assets/images/homepage/0343_NUT_PP_Featured_Ribbon_111522.png) left top no-repeat;
  background-size: contain;
  height: 100px;
  width: 470px;
  left: -120px;
}

.feed-it_background {
  background: url(/public/assets/images/homepage/donate-section-home.jpg) no-repeat;
  background-size: cover;
  padding: 60px 0;
}

.twin-section::after {
  background-color: #f9f9f5;
  transform: translateX(-50%);
  position: absolute;
  content: ' ';
}

.goto_faq-section {
  padding: 90px;
  text-align: left;
}

.goto_faq-section h2 {
  text-align: left;
  margin-bottom: 30px;
}

.start_earning_section {
  padding: 90px;
  text-align: left;
}

.start_earning_section h2 {
  text-align: left;
  margin-bottom: 30px;
}

.earnpoint-twin .goto_faq-section,
.earnpoint-twin .start_earning_section {
  padding: 0px 30px;
}

/* post login */
.wahtsnew_section h2 {
  text-align: center;
  padding-bottom: 30px;
  text-transform: uppercase;
  /* border-bottom: 2px solid #333333; */
}

.bottom-banner {
  background: url(/public/assets/images/homepage/RfrAFrnd_Bnr-Desktop.png) 100% no-repeat;
  height: 400px;
  background-size: cover;
}

.earnpoint-bottom-banner {
  background: url(/public/assets/images/earn-points/RwrdYrslf_Bnr-Desktop.png) 100% no-repeat;
  height: 400px;
  background-size: cover;
}

.reward-bottom-banner {
  background: url(/public/assets/images/reward-assets/Wys2ErnBnr-Desktop.png) 100% no-repeat;
  height: 400px;
  background-size: cover;
  padding-top: 70px;
}

/* end post login */
/* end home page */

/* regisration */
.animal-list label {
  width: 19%;
  margin-bottom: 0;
  font-family: Open Sans, sans-serif !important;
}

.MuiTypography-body1 {
  font-family: Open Sans, sans-serif !important;
}

.col-form-label {
  font-family: Open Sans, sans-serif !important;
  font-weight: normal;
}

.verification-page img {
  width: 130px;
}

.verification-page h3 {
  color: #006228;
  margin-top: 14px;
}

.reward-congratulation .material-icons {
  font-size: 70px;
  color: #00bd3f;
  margin-bottom: 30px;
}

.auto-fill-data {
  background-color: #d3d3d3 !important;
}

.custom-tooltip-wrap .MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.custom-tooltip-wrap .MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.custom-tooltip-wrap .btn {
  min-width: 26px;
  padding: 0;
  height: 26px;
  border-radius: 80px;
  position: relative;
  left: 6px;
  font-size: 17px;
  margin: 0;
  top: -3px;
  background-color: #006228;
  color: #fff;
}

.tooltip.show {
  opacity: 1;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #006228 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #006228 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #006228 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #006228 !important;
}

/* end regitration */
/* FAQ */
.page .faq .accordion .card-body {
  font-size: 20px;
}

.faq-wrapper {
  width: 100%;
  display: block;
}

.faq-wrapper img {
  width: 100%;
  align-items: center;
}

.faq-top-banner {
  max-height: 600px;
  overflow: hidden;
}

.faq-banner {
  background: url(/public/assets/images/faq/FAQs_Topics_Banners/FAQ_TOPBanners_111422_Desktop-About.png) center center no-repeat;
  background-size: cover;
  height: 500px;
}

.faq-bot-banner {
  background: url(/public/assets/images/faq/FAQ_Bottom_Banner/FAQBtm-Desktop.png) 100% 100% no-repeat;
  height: 400px;
  background-size: cover;
  padding: 90px 0;
}

.faq-bot-banner h2 {
  color: #fff;
  margin-bottom: 25px;
}

.faq-page .heading-link {
  color: #333333;
  text-decoration: none;
}

.faq-page .heading-link:hover {
  color: #333333 !important;
  text-decoration: none;
}

.card-img-overlay {
  background-color: rgba(51, 51, 51, 0.4);
}

/* end FAQ */

/* post login */
.banner-wrapper {
  width: 100%;
  display: block;
}

.banner-wrapper img {
  width: 100%;
  align-items: center;
}

.top-banner {
  /* min-height: 100vh; */
  overflow: hidden;
}

.top-banner .card-img-overlay {
  background-color: rgba(51, 51, 51, 0);
}

/* account */
.account-bot-banner {
  background: url(/public/assets/images/account/AcntPg_BttmBnr_Desktop.png) 100% 100% no-repeat;
  height: 400px;
  background-size: cover;
  padding: 90px 0;
}

.account-bot-banner h2 {
  color: #fff;
  margin-bottom: 25px;
}

.user-pages .page-section .req-highlight {
  border-bottom-color: #f7b500 !important;
  background-color: #fdf8eb !important;
}

.theme-profile-firstName input::placeholder,
.theme-profile-firstName input::-moz-placeholder,
.theme-profile-firstName input::-webkit-input-placeholder {
  opacity: 1;
}

.table-report-div .react-bootstrap-table,
.react-bootstrap-table {
  text-align: center;
}

.captcha p {
  text-align: center !important;
  display: inline-block;
}

/* end account */
/* Earn points page */
.earnpoint .top-banner {
  height: initial;
}

.earnpoint-hero-banner {
  padding-top: 150px;
  /* background: url(/public/assets/images/earn-points/EarnPts_HeroBn-Desktop.png) 100% no-repeat;
    background-size: cover;
    display: block;
    height: 100vh;
    padding: 18% 0 0;
    display: flex; */
}

.earnpoint-hero-banner h2 {
  color: #006228;
}

.earnpoint-page,
.twin-section {
  background: -webkit-linear-gradient(90deg, #f0f0e6 50%, #f9f9f5 50%);
  background: -o-linear-gradient(90deg, #f0f0e6 50%, #f9f9f5 50%);
  background: -moz-linear-gradient(90deg, #f0f0e6 50%, #f9f9f5 50%);
  background: linear-gradient(90deg, #f0f0e6 50%, #f9f9f5 50%);
}

.earnpoint-page .goto_faq-section,
.earnpoint-page .start_earning_section {
  padding: 30px 20px;
}

.refer-custom-link-text {
  background-color: #f0f0e6;
  padding: 10px 15px;
  display: block;
  width: 100%;
  line-height: 50px;
}

.refer-custom-link-text:hover {
  text-decoration: none;
  color: #333333;
  cursor: pointer;
}

.category-filter-div .form-control {
  display: inline-block;
  width: 110px;
  margin-left: 10px;
}

/* End Earn points page */
/* Rewards page */
.breadcrumb-item + .breadcrumb-item {
  font-size: 16px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ' ';
}

.reward-hero-banner {
  padding-top: 10%;
}

.bg-reward-pro {
  background-color: #ccc;
}

.reward-product-card .card {
  background-color: transparent;
}

.profile-complete {
  background-color: #006228;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 20px;
}

.profile-complete h3 {
  color: #fff;
  font-family: 'HelveticaNeue-Bold';
}

.coupon-card .card {
  border: none;
  box-shadow: none;
}

.coupon-card .card .activity-block:hover {
  background: none;
}

.coupon-card .activity-info {
  margin: 0px 15px 20px;
}

/* end Rewards page */

.activity-block a {
  text-decoration: none;
}

.activity-block a:hover {
  text-decoration: none;
  color: #000;
}

.activity-check {
  position: absolute;
  right: 10px;
  top: 10px;
}

.activity-check label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: -15px;
  position: absolute;
  top: 0;
  width: 28px;
}

.activity-check label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.activity-check input[type='checkbox'] {
  visibility: hidden;
}

.activity-check input[type='checkbox']:checked + label {
  background-color: #333333;
  border-color: #333333;
}

.activity-check input[type='checkbox']:checked + label:after {
  opacity: 1;
}

/* quiz questions */
.questions-card {
  margin-bottom: 120px;
}

.questions-card .card .MuiFormLabel-root {
  color: #000 !important;
}

.questions-card .card .MuiFormControlLabel-root {
  margin: -4px 0px -4px 0px !important;
}

.questions-card .card {
  background-color: #efefef;
  padding: 20px;
  margin: 10px 0px;
  color: #000;
  width: 100%;
  display: block;
  cursor: default;
  box-shadow: none;
}

.questions-card .card:hover {
  box-shadow: none;
}

/* end quiz questions */

/* watch video */
.video-controls {
  position: absolute;
  display: flex;
  width: 95.6%;
  height: 50px;
  z-index: 3;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: bottom 1s;
  -khtml-transition: bottom 1s;
  -moz-transition: bottom 1s;
  -ms-transition: bottom 1s;
  transition: bottom 1s;
}

.video-controls .btn-link,
.video-controls span {
  color: #fff;
}

.overlay-play-button {
  /* Used to position the overlay */
  position: relative;
}

.overlay-play-button__overlay {
  /* Position */
  left: 0;
  position: absolute;
  top: 0;

  /* Take full size */
  height: calc(100% - 6px);
  width: 100%;

  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Add a dark background */
  background-color: rgba(0, 0, 0, 0.1);
}

.overlay-play-button__play {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 30px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.6);

  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.3s all ease;
}

.overlay-play-button__overlay:hover .overlay-play-button__play {
  border-width: 30px 0 30px 40px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.95);
}

.video-controls .progress {
  background-color: #006228 !important;
}

.video-controls .bg-success {
  background-color: #fff !important;
}

/* end watch video */
/* submit code */
.response-message-div.bg-success h4,
.response-message-div.bg-success p {
  color: white;
}

/* end submit code */
/* end post login */

/* Contest Page */
.contest-logged-hero-banner {
  background: url(/public/assets/images/contest/0343_NUT_PPWeb_Contest_DT_120922.png) 100% no-repeat;
  background-size: cover;
  display: block;
  color: #fff;
  height: 100vh;
  padding: 24% 0 8%;
  display: flex;
}

.contest-hero-banner {
  padding-top: 20%;
  padding-left: 13%;
}

/* end Contest Page */

/* Footer style */
footer {
  background-color: #141414;
  border-top: 5px solid #e2bb46;
  padding: 15px 0 20px;
}

footer a {
  padding: 20px;
  font-weight: 600;
  text-align: center;
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  letter-spacing: -0.45px;
  color: #fff;
}

.footer-social a {
  font-size: 24px;
}

footer a:hover {
  color: #e2bb46;
}

.copy-text {
  text-align: left;
}

footer .footer-link li {
  display: inline-block;
}

footer .footer-link a {
  text-decoration: none;
}

.footer-link ul {
  padding-left: 0;
}
/* End Footer Style */
#teconsent a {
  cursor: pointer;
}

.upcoming {
  padding-top: 180px;
}

.upcominng-faq-heading {
  font-size: 90px;
  color: #00bd3f;
}

.rewards-prev-div .form-group {
  width: 40%;
}

.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-colorSecondary.Mui-checked {
  color: #006228 !important;
}

.Mui-focused.MuiInput-underline:after {
  border-bottom: 2px solid #006228 !important;
}

.answer-tick {
  position: absolute;
  margin-top: 3px;
  margin-left: 5px;
}

.t-row {
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #ccc;
}

.t-row p {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
}

#redeem-now-btn {
  width: 100%;
  max-width: 360px;
}

.btn-update-address {
  position: relative;
  padding: 2px 10px;
  background-color: #6b6b6b;
  color: #fff;
  text-decoration: none;
  display: flex;
}

.btn-update-address .material-icons {
  margin-right: 5px;
}

/* media queries */
/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
  .desktop-hide {
    display: none !important;
  }

  .user-menu {
    margin-left: 16px;
    width: 100%;
  }

  .user-menu .dropdown-menu {
    min-width: 260px;
  }

  .user-menu .dropdown-menu .nav-item .nav-link {
    line-height: 20px;
  }

  .user-pos {
    position: absolute;
    right: 74px;
    top: 18px;
  }

  .mob-menu-pos {
    float: right !important;
  }

  .user-menu .dropdown-toggle::after {
    display: none;
  }

  .user-menu-icon {
    margin-right: 0;
  }

  h2 {
    font-size: 38px;
  }

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }

  .navbar-dark {
    height: 75px !important;
    border-bottom: 10px solid #e2bb46;
  }

  .navbar-nav {
    padding-bottom: 20px;
  }

  .navbar-nav .nav-item .nav-link {
    line-height: 40px;
  }

  .navbar-dark .navbar-brand {
    line-height: 60px;
    margin-right: 16px;
  }

  .navbar-dark .navbar-brand img {
    height: 60px;
  }
  .navbar-dark .navbar-brand span {
    font-size: 1.3rem;
    margin-top: 0;
  }

  .navbar {
    padding: 0;
  }
  .nutrena_logo {
    height: 36px;
  }

  .navbar-dark .navbar-toggler {
    border: none;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    right: 10px;
    top: 15px;
  }
  .navbar-collapse {
    background-color: #141414;
  }
  .perks-content-un {
    margin: 0 !important;
    padding: 5% !important;
    text-align: center;
  }
  .perks-content-un-s {
    margin-left: -15px !important;
    margin-right: -15px !important;
    text-align: left;
  }
  .user-menu .navbar-divide-bot {
    background-color: rgba(240, 240, 230, 0.4);
    margin-bottom: 15px;
  }

  .user-menu ul.dropdown-menu {
    padding-top: 0;
  }

  .plaidpark_logo img {
    padding-top: 8px;
    width: 70px;
    padding-bottom: 8px;
  }

  .mya-tabs .btn-primary,
  .mya-tabs .btn-light {
    padding: 10px 15px;
  }

  /* home page */

  .home-hero-banner {
    background: url(/public/assets/images/homepage/mobile_banner_without_button.png) center top no-repeat;
    background-size: cover;
    padding: 80% 0px 3% 0px;
    height: auto;
  }

  .home-logged-hero-banner {
    background: url(/public/assets/images/homepage/mobile_banner_without_button.png) center top no-repeat;
    background-size: cover;
    height: inherit;
    padding: 85% 5px 0% 5px;
  }

  .contest-logged-hero-banner {
    background: url(/public/assets/images/contest/0343_NUT_PPWeb_Contest_Mob_120922.png) center top no-repeat;
    background-size: cover;
    height: inherit;
    padding: 30% 50px 80px 50px;
  }
  .hero-banner-cta-wrap {
    text-align: center;
  }

  .hero-banner-cta-wrap .btn-primary {
    display: block;
    text-align: center;
    margin: 20px auto 0;
    font-size: 20px;
    font-family: 'HelveticaNeue-Bold';
    min-width: 160px;
    padding: 15px;
  }

  .easy_rewards h2 {
    font-size: 40px;
    text-align: left;
  }

  .easy_rewards ol {
    margin-top: 20px;
    margin-bottom: 45px;
  }

  .easy_rewards ol li {
    font-size: 22px;
    line-height: 26px;
  }

  .feed-it_featured {
    height: 90px;
  }

  .feed-it_background h2 {
    font-size: 36px !important;
  }

  .feed-it_featured::after {
    background: url(/public/assets/images/homepage/0343_NUT_PP_Featured_Ribbon_111522.png) left top no-repeat;
    background-size: contain;
    height: 70px;
    width: 370px;
    left: -85px;
  }

  .feed-it_background {
    background: url(/public/assets/images/homepage/0343_NUT_PP_Splsh_FtrdBttm_111422-Mobile.png) right 0px no-repeat;
    background-size: cover;
    padding: 40px 30px;
    height: 1100px;
  }

  .feed-it_background p {
    font-size: 22px;
    padding: 20px 0 0px;
  }

  .feed-it_background .btn {
    width: 100%;
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .page-section {
    padding: 20px 20px;
  }

  .earnpoint-page,
  .twin-section {
    background: -webkit-linear-gradient(180deg, #f0f0e6 50%, #f9f9f5 50%);
    background: -o-linear-gradient(180deg, #f0f0e6 50%, #f9f9f5 50%);
    background: -moz-linear-gradient(180deg, #f0f0e6 50%, #f9f9f5 50%);
    background: linear-gradient(180deg, #f0f0e6 50%, #f9f9f5 50%);
  }

  .earnpoint-twin .goto_faq-section,
  .earnpoint-twin .start_earning_section {
    padding: 0px 30px;
    margin-top: 30px;
  }

  /* slider */

  /* register */
  .animal-list label {
    width: 47%;
  }

  /* end register */

  /* post login */
  .top-banner {
    height: initial;
  }

  .wahtsnew_section h2 {
    /* text-align: center;
          padding-bottom: 30px; */
  }

  .bottom-banner {
    background: url(/public/assets/images/homepage/RfrAFrnd_Bnr-Mobile.png) 100% no-repeat;
    height: 600px;
    background-size: cover;
  }

  .earnpoint-bottom-banner {
    background: url(/public/assets/images/earn-points/RwrdYrslf_Bnr-Mobile.png) 100% no-repeat;
    height: 620px;
    background-size: cover;
  }

  .reward-bottom-banner {
    background: url(/public/assets/images/reward-assets/Wys2ErnBnr-Mobile.png) 100% no-repeat;
    height: 560px;
    background-size: cover;
    padding-top: 310px;
  }

  .start_earning_section,
  .goto_faq-section {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }

  .start_earning_section img,
  .goto_faq-section img {
    width: 100px;
    text-align: center;
    margin: auto;
  }

  .get-access-section p {
    font-size: 22px;
  }

  /* end post login */
  /*  end home page */
  /* Earnpoint page */
  .earnpoint-hero-banner {
    /* background: url(/public/assets/images/earn-points/EarnPts_HeroBnr-Mobile.png) center top no-repeat;
      background-size: cover;
      height: inherit;
      padding: 140px 50px 80px 50px; */
    /* padding-top: 55%; */
    padding: 75% 0px 0px 0px;
  }

  .earnpoint-hero-banner h2 {
    line-height: 44px;
  }

  .earnpoint-page .goto_faq-section,
  .earnpoint-page .start_earning_section {
    padding: 30px 10px;
  }

  /* end Earnpoint page */
  /* Rewards page */
  .reward-hero-banner {
    /* background: url(/public/assets/images/homepage/Home_Login-HeroBnr-Mobile.png) center top no-repeat;
      background-size: cover;
      height: inherit;
      padding: 140px 50px 80px 50px; */
    padding-top: 40px;
  }

  .reward-banner-btn {
    padding-top: 160px;
  }

  .reward-banner-btn .btn-primary {
    padding: 10px 15px;
    margin-top: 20px;
  }

  /* end Rewards page */
  .contest-hero-banner {
    padding-top: 65%;
    padding-left: 10%;
    padding-right: 10%;
  }

  /* FAQ */
  .faq-page .page-section {
    padding: 90px 0;
  }

  .faq-top-banner {
    display: none;
  }

  .faq-banner {
    background: url(/public/assets/images/faq/FAQ_Bottom_Banner/FAQBtm-Mobile.png) center top no-repeat;
    background-size: cover;
    height: 500px;
  }

  .faq-bot-banner {
    background: url(/public/assets/images/faq/FAQ_Bottom_Banner/FAQBtm-Mobile.png) center top no-repeat;
    background-size: cover;
    height: 600px;
    padding: 50px 0;
  }

  .page .faq .accordion .card-body li {
    word-break: break-all;
  }

  /* end FAQ */

  .refer-wrapper {
    padding-bottom: 350px;
  }

  /* post login  */
  /* user pages */
  .user-pages .page-section {
    padding-left: 0;
    padding-right: 0;
  }

  /* end user pages */
  /* account */
  .account-bot-banner {
    background: url(/public/assets/images/account/AcntPg_BttmBnr_Mobile.png) center top no-repeat;
    background-size: cover;
    height: 600px;
    padding: 50px 0;
  }

  /* end account */

  /* end post login */

  /* Footer */
  .copy-text {
    text-align: center;
  }

  .footer-link ul {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  footer .footer-link li {
    float: initial;
    text-align: center;
  }

  footer,
  footer a {
    font-size: 16px;
  }

  .footer-social a {
    font-size: 22px;
  }
  .copy-text p {
    font-size: 1em !important;
    font-weight: 600 !important;
    font-family: Open Sans, sans-serif;
    color: #fff;
    text-transform: none;
    margin: 15px 0 0;
  }
  .upcoming {
    padding-top: 100px;
  }

  .MuiFormControlLabel-root {
    vertical-align: text-bottom !important;
    display: inline-block !important;
  }

  .term-lable .MuiTypography-body1 {
    display: inline-block;
    width: calc(100% - 50px);
  }

  .feed-it_section .head-col {
    width: 100%;
  }

  .feed-it_section .text-col {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 359.98px) {
  .navbar-dark .navbar-brand {
    margin-right: 10px;
    line-height: 50px;
  }

  .navbar-dark .navbar-brand img {
    width: 50px;
  }

  .nutrena_logo {
    height: 30px;
  }

  .user-pos {
    right: 70px;
  }

  .home-hero-banner {
    /* padding: 140px 40px 350px 40px; */
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 85%;
  }

  .feed-it_background h2 {
    font-size: 32px !important;
  }

  .easy_rewards h2 {
    font-size: 28px;
  }

  .easy_rewards ol li {
    font-size: 18px;
    line-height: 20px;
  }
  .reward-hero-banner {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 4%;
  }

  .reward-banner-btn {
    margin-top: 20px;
  }

  .reward-banner-btn .btn {
    margin-top: 10px;
  }

  .reward-hero-banner .btn-primary {
    font-size: 14px;
  }

  .reward-bottom-banner {
    height: 520px;
    padding-top: 290px;
  }

  .contest-hero-banner {
    padding-top: 61%;
    padding-left: 8%;
    padding-right: 7%;
  }
}

@media (min-width: 360px) and (max-width: 389.98px) {
  .reward-hero-banner {
    padding-top: 4%;
  }

  .reward-hero-banner .btn-primary {
    font-size: 16px;
  }

  .reward-hero-banner h2 {
    font-size: 30px;
  }

  h2 {
    font-size: 34px;
  }
}

@media (min-width: 390px) and (max-width: 414px) {
  h2 {
    font-size: 34px;
  }

  .home-hero-banner {
    padding-top: 40%;
    padding-bottom: 75%;
  }

  .reward-hero-banner {
    padding-top: 50px;
  }

  .reward-banner-btn {
    padding-top: 220px;
  }
}

@media (min-width: 412px) and (max-width: 599.98px) {
  .home-hero-banner {
    padding-top: 45%;
    padding-bottom: 85%;
  }

  .reward-hero-banner {
    padding-top: 40px;
  }

  .reward-banner-btn {
    padding-top: 280px;
  }

  /* .contest-hero-banner{
    padding-top: 260px;
  } */
}

/* end Extra small devices (portrait phones, less than 576px) */
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .user-pos {
    position: absolute;
    right: 100px;
    top: 10px;
  }

  .navbar-dark .navbar-brand {
    margin-right: 20px;
  }
  .navbar-dark .navbar-toggler {
    border: none;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .nutrena_logo {
    width: 160px;
  }

  /* .home-hero-banner {
    background: url(/public/assets/images/homepage/desktop banner without button.png) 100% no-repeat;
    background-size: cover;
  } */

  .home-logged-hero-banner {
    background: url(/public/assets/images/homepage/desktop_banner_without_button.png) 100% no-repeat;
    background-size: cover;
    padding: 10% 0 15%;
  }

  .feed-it_section .head-col {
    width: 70%;
  }

  .feed-it_section .text-col {
    width: 60%;
  }

  .reward-banner img {
    width: 130%;
  }

  .reward-banner .col-md-6 {
    width: 74% !important;
  }

  .reward-banner-btn {
    margin-top: 20px;
  }

  .reward-banner-btn .btn {
    margin-top: 10px;
  }

  .bottom-banner {
    background-position-x: 30%;
    height: 320px;
  }

  .faq-bot-banner {
    background-position-x: 80%;
  }

  .reward-hero-banner {
    padding-top: 3%;
  }

  .reward-hero-banner h2 {
    font-size: 34px;
  }

  .reward-bottom-banner {
    height: initial;
    background-size: 180%;
    background-position-x: 5%;
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

/* END Small devices (landscape phones, 576px and up) */
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-dark .navbar-brand {
    margin-right: 10px;
  }
  .user-pos {
    position: absolute;
    right: 80px;
  }
  .navbar-dark .navbar-toggler {
    border: none;
    color: #fff;
    position: absolute;
    right: 20px;
    top: 30px;
  }

  .navbar-nav .nav-item .nav-link {
    padding-left: 15px;
    padding-right: 15px;
  }

  .reward-banner img {
    width: 110%;
  }

  .reward-banner .col-md-6 {
    width: 60% !important;
    max-width: 60% !important;
    flex: 0 0 60%;
  }

  .reward-hero-banner {
    padding-top: 8%;
  }

  .reward-banner-btn .btn {
    margin-bottom: 10px;
  }

  .contest-hero-banner {
    padding-right: 10%;
    padding-top: 20%;
    padding-left: 12%;
  }

  .earnpoint-hero-banner {
    padding-top: 100px;
  }

  .bottom-banner {
    background-position-x: 40%;
  }
  .earnpoint-twin .goto_faq-section,
  .earnpoint-twin .start_earning_section {
    padding: 0px 30px;
  }
  .earnpoint-page .goto_faq-section,
  .earnpoint-page .start_earning_section {
    padding: 30px;
  }
  .earnpoint-twin .btn-primary,
  .earnpoint-page .goto_faq-section .btn-primary,
  .earnpoint-page .start_earning_section .btn-primary {
    min-width: 155px;
    padding: 10px 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .user-pos {
    top: 27px;
  }
  .navbar-dark .navbar-toggler {
    right: 35px;
    top: 30px;
  }
  .navbar-nav .nav-item .nav-link {
    padding: 15px 10px !important;
  }
  .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  .home-hero-banner {
    background: url(/public/assets/images/homepage/desktop_banner_without_button.png) 76% no-repeat;
    background-size: cover;
    display: block;
    color: #fff;
    height: 100vh;
    padding: 14% 0;
    display: flex;
  }

  .home-hero-banner .container {
    max-width: 650px;
  }

  .top-banner {
    min-height: inherit;
  }

  .earnpoint-hero-banner {
    padding-top: 4%;
  }

  .earnpoint .banner-img,
  .contest .banner-img {
    width: 112%;
  }
  .earnpoint-bottom-banner {
    background-position-x: 65%;
    height: 320px;
  }

  .contest-hero-banner {
    padding-top: 20%;
    padding-left: 12%;
  }
  .earnpoint-twin .goto_faq-section,
  .earnpoint-twin .start_earning_section {
    padding: 0px 20px;
  }
  .earnpoint-page .goto_faq-section,
  .earnpoint-page .start_earning_section {
    padding: 30px 40px;
  }
  .earnpoint-twin .goto_faq-section img,
  .earnpoint-twin .start_earning_section img,
  .earnpoint-page .goto_faq-section img,
  .earnpoint-page .start_earning_section img {
    width: 60%;
  }
  .earnpoint-twin .btn-primary,
  .earnpoint-page .goto_faq-section .btn-primary,
  .earnpoint-page .start_earning_section .btn-primary {
    min-width: 155px;
    padding: 10px 20px;
  }
  .earnpoint-twin .card-body,
  .earnpoint-page .goto_faq-section .card-body,
  .earnpoint-page .start_earning_section .card-body {
    padding: 1.25rem 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .user-pos {
    top: 27px;
  }
  .navbar-nav .nav-item .nav-link {
    padding: 15px 10px !important;
  }

  .page-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h2 {
    font-size: 38px;
  }

  .home-hero-banner {
    background: url(/public/assets/images/homepage/desktop_banner_without_button.png) 100% no-repeat;
    background-size: cover;
  }

  .home-logged-hero-banner {
    background: url(/public/assets/images/homepage/Home_Login-HeroBnr-Desktop.png) 100% no-repeat;
    background-size: cover;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .home-hero-banner .btn {
    margin-bottom: 30px;
  }

  .home-logged-hero-banner h2 {
    margin-bottom: 30px;
  }

  .top-banner {
    min-height: 34vh;
  }

  .easy_rewards h2 {
    font-size: 40px;
  }

  .easy_rewards ol li {
    font-size: 22px;
  }

  .get-access-section h2 {
    font-size: 38px;
  }

  .feed-it_featured {
    height: 80px;
  }

  .feed-it_featured::after {
    height: 60px;
    left: -70px;
  }

  .feed-it_section .head-col {
    width: 80%;
  }

  .feed-it_section .text-col {
    width: 70%;
  }

  .goto_faq-section {
    padding: 60px 40px;
  }

  .start_earning_section {
    padding: 60px 40px;
  }

  .earnpoint-twin .goto_faq-section,
  .earnpoint-twin .start_earning_section,
  .earnpoint-page .goto_faq-section,
  .earnpoint-page .start_earning_section {
    padding: 0px 10px;
  }
  .earnpoint-twin .card-body,
  .earnpoint-page .goto_faq-section .card-body,
  .earnpoint-page .start_earning_section .card-body {
    padding: 1.25rem 0;
  }
  /* .bottom-banner{
    background-position-x: -200px;
  } */
  .reward-bottom-banner {
    background-position-x: 0px;
    height: 300px;
    padding-top: 30px;
  }

  .earnpoint-hero-banner {
    padding-top: 7%;
  }

  .earnpoint .top-banner {
    min-height: 33vh;
  }

  .earnpoint .banner-img {
    width: 112%;
  }

  .earnpoint-page .goto_faq-section,
  .earnpoint-page .start_earning_section {
    padding: 30px 10px;
  }

  .reward-hero-banner {
    padding-top: 10px;
  }

  .reward-banner-btn {
    margin-top: 20px;
  }

  .reward-banner-btn .btn {
    margin-top: 10px;
  }

  .earnpoint-bottom-banner {
    background-position-x: 40%;
    height: 380px;
  }

  /* .contest-hero-banner{
    padding-top: 80px;
  } */
}
@media (min-width: 992px) and (max-width: 1024px) {
  .user-pos {
    position: relative;
    right: 0;
  }
}

/* END Medium devices (tablets, 768px and up) */
/* end media queries */

@media (max-width: 1400px) {
  .reward-bottom-banner {
    background-position-x: 10%;
  }
}

@media (min-width: 992px) {
  .home-hero-banner {
    background: url(/public/assets/images/homepage/desktop_banner_without_button.png) 100% no-repeat;
    background-size: cover;
    display: block;
    color: #fff;
    height: 100vh;
    padding: 8% 0;
    display: flex;
  }
  .home-logged-hero-banner {
    background: url(/public/assets/images/homepage/desktop_banner_without_button.png) 100% no-repeat;
    background-size: cover;
    display: block;
    color: #fff;
    height: 100vh;
    padding: 10% 0 8%;
    display: flex;
  }

  .earnpoint-hero-banner {
    padding-top: 20%;
  }

  .feed-it_section .head-col {
    width: 50%;
  }

  .feed-it_section .text-col {
    width: 50%;
  }

  .reward-banner img {
    width: 100%;
  }

  .reward-hero-banner {
    padding-top: 7%;
  }
  .reward-hero-banner2 {
    padding-top: 15%;
  }
  .earnpoint .top-banner,
  .reward-banner .top-banner {
    min-height: inherit;
  }

  .reward-banner-btn {
    margin-top: 40px;
  }

  .reward-banner-btn .btn {
    margin-top: 0px;
  }

  .bottom-banner {
    background-position-x: 50%;
  }
}
@media (min-width: 1200px) {
  .earnpoint-twin .goto_faq-section,
  .earnpoint-twin .start_earning_section {
    padding: 0px 90px;
  }
  .earnpoint-page .goto_faq-section,
  .earnpoint-page .start_earning_section {
    padding: 30px 70px;
  }
}
@media (max-width: 1100px) {
  .navbar-nav .nav-item .nav-link {
    padding: 0px 10px;
  }
  .navbar-nav .nav-item:last-child {
    margin-right: 10px;
  }
}

@media (min-width: 1400px) {
  /* .contest-hero-banner {
    padding-top: 280px;
    padding-left: 320px;
  } */
  .earnpoint-hero-banner {
    padding-top: 10%;
  }

  .bottom-banner {
    background-position-y: 0;
  }
}

@media (min-width: 2000px) {
  /* .contest-hero-banner {
    padding-top: 450px;
    padding-left: 560px;
  } */
  .reward-hero-banner {
    padding-top: 15%;
  }

  .earnpoint-hero-banner {
    padding-top: 20%;
  }
}
.black {
  color: #141414;
}
.h3-font-weight {
  font-weight: normal;
}

@media (min-width: 576px) {
  .mobile-hide {
    display: none !important;
  }
}
.white-link,
.white-link:hover {
  text-decoration: underline;
  color: #fff;
}
.header-1 {
  font-size: 50px !important;
  line-height: 50px !important;
}
.perks-content-5 {
  width: 60%;
  float: left;
  margin-right: -20%;
  height: auto;
  margin-left: 11%;
  margin-top: 3%;
}
.img-perks-5 {
  width: 40%;
  float: right;
  position: inherit;
}
.perks-content-4 {
  margin-left: -10%;
  height: auto;
  margin-top: 20%;
}
.img-perks-7 {
  padding-top: 5%;
}
.img-perks-7 img {
  bottom: 0;
  position: absolute;
  width: 90%;
}
@media (max-width: 830px) {
  .footer-link ul li {
    width: 90%;
    padding: 3%;
  }
  .img-perks-6 img {
    display: none;
  }
  .perks-content-5 {
    width: 100%;
    float: right;
    margin: 0% !important;
    height: auto;
  }
  .img-perks-5 {
    width: 100%;
    float: left;
  }
  .img-perks {
    text-align: center;
    padding: 10%;
  }
  .img-perks img {
    max-width: 90%;
  }
  .perks-content-4 {
    height: auto;
    margin-top: 10%;
  }

  .home-heading-sm,
  .p-sm {
    text-align: left !important;
  }
  .img-perks-7 {
    padding: 0% !important;
  }
  .img-perks-7 img {
    position: relative;
  }
}
.support-main-heading {
  font-size: 2.441em;
  font-weight: 500;
  color: #fff;
  font-family: Oswald, sans-serif;
  letter-spacing: -0.45px;
  margin: 0.67em 0 0.33em;
  letter-spacing: normal;
  line-height: normal;
  text-transform: capitalize;
}
.support-description {
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  font-family: Open Sans, sans-serif;
  margin: 0 0 1em;
  letter-spacing: normal;
  line-height: normal;
}
.section-content {
  width: 95% !important;
  margin: 0 auto;
}
.MuiInputBase-input {
  color: #e2bb46;
  font-size: 1em;
  font-weight: 500;
  font-family: Open Sans, futura-pt, sans-serif;
  line-height: 1;
}
.textfield-bottom-border {
  border-bottom: 1px solid !important;
}
.textarea-bottom-border {
  border: 1px solid !important;
}
.MuiInputBase-root,
.MuiTypography-colorTextSecondary,
.MuiInput-inputMultiline,
.input-label {
  color: #fff !important;
}
.outlined-multiline-static textarea {
  background-color: transparent !important;
  color: #fff !important;
}
.MuiFilledInput-adornedStart {
  padding-left: 0 !important;
}
.MuiInputAdornment-positionStart {
  margin-right: 5% !important;
}
.Mui-error {
  margin-left: 0 !important;
}
.support-form-text-p {
  color: #fff !important;
}
.btn-primary2 {
  width: 198px;
  color: #141414;
  background: #e2bb46;
  border: none;
  border-color: #222;
  padding: 10px 45px;
  margin-top: 30px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.45px;
  font-size: 1.25em;
  transition: all 0.4s ease-in-out;
  margin-bottom: 20px;
  border-radius: 50px;
  font-family: Oswald, sans-serif;
  cursor: pointer;
}
.terms-block {
  background-color: #fff;
  padding: 0% 5%;
}
.terms-main-heading {
  font-size: 2.441em;
  font-weight: 500;
  color: #fff;
  font-family: Oswald-SemiBold, sans-serif;
  letter-spacing: -0.45px;
  margin: 0.67em 0 0.33em;
  letter-spacing: normal;
  line-height: normal;
  text-transform: capitalize;
  color: #000;
}
.terms-description {
  font-size: 1em;
  font-weight: 500;
  color: #fff;
  font-family: Open Sans, sans-serif;
  margin: 0 0 1em;
  letter-spacing: normal;
  line-height: normal;
  color: #000;
}
.terms-link,
.terms-link:hover {
  color: #00f;
  border-bottom: 1px solid black;
}
.eligible_subheadings {
  max-width: 820px;
  margin: 0 auto;
}

.eligible_subheadings .subheading {
  font-size: 1.563em;
  font-weight: 300;
  letter-spacing: -0.45px;
  line-height: normal;
  font-family: Oswald, sans-serif;
}
.eligible_subheadings .subheading_para {
  color: #e2bb46;
  font-family: Oswald, sans-serif;
  line-height: normal;
  margin: 0;
}
.eligible_subheadings .subheading_para a {
  text-decoration: underline;
  text-transform: none;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
}
.eligible_subheadings .subheading_para a:hover {
  color: #9a0400;
}
.participating-movies {
  background: #141414ab;
  width: 100%;
  box-shadow: 0 0 12px -5px #c4a35d80;
}

.modal-content {
  height: 100%;
  background: url(/public/assets/images/background-film-white.png) no-repeat !important;
  background-color: #141414 !important;
  background-position: center;
  position: relative;
  border: 1px solid #fff;
  box-shadow: 0 0 54px #c4a35d4d;
}
.modal-content .modal-body {
  background: url(/public/assets/images/background-film-white.png) no-repeat !important;
  height: 100%;
}
.modal-dialog .dialog-color {
  color: #e2bb46 !important;
  margin-top: 2px;
}
.modal-dialog .close-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  color: #e2bb46 !important;
}
.react-bootstrap-table table,
.react-bootstrap-table table td {
  color: #fff !important;
  border: none !important;
}
.react-bootstrap-table table th {
  border: none !important;
}
.react-bootstrap-table table tbody tr:nth-child(even) {
  background-color: #000;
  text-align: left;
}
.react-bootstrap-table table tbody tr:nth-child(odd) {
  background-color: #141414;
  text-align: left;
}
.react-bootstrap-table table thead tr,
.react-bootstrap-table-pagination,
.react-bootstrap-table-pagination-custom {
  background: #e2bb46 !important;
}
.react-bootstrap-table table thead tr th,
.react-bootstrap-table-pagination-custom {
  color: #000 !important;
  font-weight: 600;
  text-align: left;
  font-family: Roboto, Helvetica Neue, sans-serif;
}
.react-bootstrap-table table {
  margin-bottom: 0;
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 30px;
  color: #000 !important;
  font-size: 12px;
}
.react-bootstrap-table-pagination-custom {
  font-size: 12px;
}
.MuiInput-root {
  margin: 0px 10px 0px 10px;
}
.right-align {
  text-align: right;
}
.react-bootstrap-table-pagination-custom .btn-link {
  color: #000;
}
.react-bootstrap-table-pagination-custom .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  color: #e2bb46;
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 40px !important;
}
.search-bar-table {
  width: 100%;
  margin: 60px auto;
  background: #e2bb46;
  height: 50px;
  border-radius: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-width: 300px;
  font-size: 1.25em;
  font-weight: 500;
  font-family: Oswald, sans-serif;
  letter-spacing: -0.45px;
  padding: 0 20px;
  color: #141414;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.search-bar-table:focus {
  width: 100%;
  margin: 60px auto;
  background: #e2bb46;
  border-radius: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-width: 300px;
  font-size: 1.25em;
  font-weight: 500;
  letter-spacing: -0.45px;
  padding: 0 20px;
  color: #141414;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-bar-table .input-group-text {
  font-size: 1.25em;
  font-weight: 500;
  font-family: Oswald, sans-serif;
  letter-spacing: -0.45px;
  padding: 0 20px;
  color: #141414;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 150px;
  border: 0;
  background-color: transparent;
  height: 50px;
}
.search-bar-table #inlineFormInputGroup {
  width: 80%;
  background: #e2bb47;
  border: none;
  border-bottom: 2px solid #9a0400;
  margin-right: 5px;
  outline: none;
  font-weight: 500;
  height: 50px;
}
.search-bar-table #inlineFormInputGroup:focus {
  box-shadow: none;
}
.react-bootstrap-table table thead tr th,
.react-bootstrap-table table tbody tr td {
  width: 25%;
}
.balloons-background {
  height: 100%;
  background: url(/public/assets/images/background-film-white-right.png) no-repeat;
  background-color: #141414;
  padding: 4% 0;
}
.balloons-background .mat-card {
  background-color: #141414;
  box-shadow: 0 0 17px -5px #c4a35d80;
  border-radius: 10px;
}
.welcome,
.welcome a {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 300;
  color: #e2bb46;
  letter-spacing: -0.45px;
  font-family: Oswald, sans-serif;
}
.yelloy-text input {
  color: #e2bb46 !important;
  padding-left: 10%;
}
.yelloy-pass input {
  color: #e2bb46 !important;
  padding-left: 5%;
}
.balloons-background input:focus,
.balloons-background input:selection {
  background: transparent !important;
}
.register-forgot-pass-links a {
  color: #fff;
  font-family: Open Sans, sans-serif;
  font-size: 1em;
  font-weight: 700;
  text-decoration: underline;
}
.balloons-background .mat-card .login-button {
  border-color: #222 !important;
  padding: 10px 45px;
  text-transform: uppercase;
  letter-spacing: -0.45px;
  font-size: 1.25em;
  transition: all 0.4s ease-in-out;
  float: right;
  border-radius: 50px;
}
.btn-sso,
.btn-sso:hover,
.btn-sso:focus,
.google-login button,
.facebook-login button {
  background-color: #fff;
  border: 1px solid black;
  color: #000;
  width: 50%;
  padding: 12px 16px;
  font-size: 16px !important;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500 !important;
  text-align: center !important;
  font-family: Roboto, sans-serif !important;
  text-transform: capitalize;
}
.balloons-background input:-webkit-autofill,
.balloons-background input:-webkit-autofill:hover,
.balloons-background input:-webkit-autofill:focus,
.balloons-background input:-webkit-autofill:active {
  -webkit-text-fill-color: #e2bb46;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 0 0 30px transparent inset !important;
}

@media (min-width: 300px) and (max-width: 800px) {
  .btn-sso,
  .btn-sso:hover,
  .btn-sso:focus,
  .google-login button,
  .facebook-login button {
    width: 100%;
  }
  .balloons-background .mat-card .login-button {
    float: left;
    margin-left: 30%;
  }
}
.input-label p,
.input-label {
  display: block;
  height: 18px;
  min-width: 30%;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  font-family: Oswald, sans-serif !important;
}

.btn-sso img,
.google-login button img,
.facebook-login button img {
  margin-right: 5%;
}
.submit-btn-full-width {
  width: 100%;
  margin-top: 8%;
  margin-left: 0% !important;
}
.MuiSelect-select.MuiSelect-select {
  color: #fff !important;
  font-size: 1em !important;
}
.MuiSelect-icon {
  color: #fff !important;
}
.mui-small-input div #filled-start-adornment {
  padding-right: 10% !important;
  text-align: right !important;
  font-size: 0.9em;
}
.MuiTooltip-touch {
  background-color: #000 !important;
}
.forgot-heading-3 {
  font-size: 1.563em;
  font-weight: 300;
  color: #e2bb46;
  letter-spacing: -0.45px;
  font-family: Oswald, sans-serif;
}
.forgot-heading-1 {
  font-size: 2.441em;
  font-weight: 500;
  font-family: Oswald, sans-serif;
  letter-spacing: -0.45px;
  color: #fff;
}
.forgot-body-text {
  font-size: 1em;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
  color: #fff;
}
.forgot-password-page {
  background-image: url(/public/assets/images/forgot-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}
.MuiFilledInput-root-trans .MuiFilledInput-root {
  background-color: transparent !important;
}
.MuiFilledInput-root-trans .MuiFilledInput-root input:-webkit-autofill,
.MuiFilledInput-root-trans .MuiFilledInput-root-webkit-autofill:hover,
.MuiFilledInput-root-trans .MuiFilledInput-root input:-webkit-autofill:focus,
.MuiFilledInput-root-trans .MuiFilledInput-root input:-webkit-autofill:active {
  -webkit-text-fill-color: #e2bb46;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 0 0 30px transparent inset !important;
}
.input-label-min p,
.input-label {
  min-width: 0% !important;
}
.forgot-password-page .btn {
  background: #e2bb46;
  padding: 10px 35px;
  border: none;
  border-radius: 25px;
  color: #fff;
  text-transform: capitalize;
  font-size: large;
  font-weight: 400;
  font-family: sans-serif;
}

.forgot-password-page .btn:hover {
  border: none;
  background-color: #000;
  color: #fff;
}
.dropdown-menu-right a {
  font-size: 0.9em;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  padding: 15px;
  -ms-transform: none;
  transform: none;
  font-family: Open Sans, sans-serif;
}
.dropdown-menu-right a .material-symbols-outlined {
  font-size: 20px;
  margin-right: 5px;
  vertical-align: middle;
}
.dropdown-menu-right .nav-item .nav-link:hover {
  color: #e2bb46 !important;
  padding-left: 25px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none !important;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}
.carousel-indicators .active {
  background-color: #e2bb46 !important;
}
.card_holder .card {
  background-color: #141414;
  border-radius: 2.5% !important;
  box-shadow: 0 0 12px -5px #c4a35d80;
}

.card_holder .card :hover {
  background: linear-gradient(45deg, #e9cf98 0%, #e2bb46 100%);
  transition: background-color 0.3s ease;
  will-change: background;
  cursor: pointer;
  border-radius: 2.5% !important;
}
.card_holder .card img {
  border-radius: 0 0 10px 10px;
  border-bottom: 5px solid #e2bb46;
}
.yellow-background {
  color: #141414 !important ;
  background: linear-gradient(45deg, #e9cf98 0%, #e2bb46 100%);
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding: 2px 0;
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  letter-spacing: -0.45px;
  font-size: 1em;
  border-radius: 10px 10px 0 0;
  z-index: 1;
}

.card_holder .card .card-button {
  width: 70%;
  background: #222;
  border-color: #e2bb46;
  color: #fff;
  box-shadow: 0 0 17px -5px #c4a35d80;
  padding: 6px;
  font-weight: 500;
  cursor: pointer;
  width: 99%;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: -0.45px;
  transition: all 0.4s ease-in-out;
  margin-bottom: 5px;
  font-size: 1em !important;
}

.card_holder .card .card-button:hover {
  background: #141414;
  border-color: #e2bb46;
  color: #e2bb46;
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
}
.card_holder .card .small-p {
  font-size: small;
  text-transform: capitalize !important;
}
.card-list-title {
  font-size: 1.953em;
  font-weight: 600;
  font-family: Oswald, sans-serif;
  letter-spacing: -0.45px;
  color: #e2bb46;
  font-weight: 500;
  margin: 0px;
  text-transform: uppercase;
  line-height: 1.15;
  text-align: left !important;
}
.link_text {
  color: #fff;
  text-decoration: underline;
}
.link_text:hover {
  color: #fff;
  text-decoration: underline;
}
